//加载用户请求模块
import request from "@/utils/";
var aixosurl = "";
if (process.env.NODE_ENV != "production") {
    // aixosurl=''
    aixosurl = "";
} else {
    aixosurl = "";
}

//在非组件模块中获取 store 必须通过这种方式
// import store from '@/store/'

//登录注册
export const login = data => {
    return request({
        method: "POST",
        url: "/app/v1_0/authorizations",
        data
    });
};

//发送验证码
export const sendSms = mobile => {
    return request({
        method: "GET",
        url: `/app/v1_0/sms/codes/${mobile}` //url带有参数，外面是转义``
    });
};

//获取登录用户token

export const getToken = data => {
    return request({
        method: "POST",
        url: "/api/getToken",
        data: data
    });
};

//获取登录用户信息
export const getCurrentUser = () => {
    return request({
        method: "GET",
        url: "/app/v1_0/user"
            //请求头，包括token用户登陆状态
            // headers: {
            //     Authorization: `Bearer ${store.state.user.token}`
            // }
    });
};

// 获取城市信息
export const getUserCity = data => {
    return request({
        method: "post",
        url: aixosurl + "/api/area",
        data
    });
};
//获取首页基本信息
export const getColumns = data => {
    return request({
        method: "post",
        url: aixosurl + "/api/columns",
        data
    });
};

//获取首页优惠券列表

export const getCouponList = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/couponList",
        data: data
    });
};
//获取Scheme url

export const generateScheme = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/generateSchemeYuan",
        data: data
    });
};
//获取协议说明

export const getCommonArticle = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/commonArticle",
        data: data
    });
};

//获取搜索热搜词接口
export const getSelectBusiSearchHotWordList = () => {
    return request({
        method: "post",
        url: aixosurl + "/api/selectBusiSearchHotWordList"
    });
};

//获取用户状态
export const getuserInfo = () => {
    return request({
        method: "POST",
        url: aixosurl + "/api/userInfo"
    });
};

// 用户领取优惠卷
export const getReceiveCoupon = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/receiveCoupon ",
        data: data
    });
};

//获取用户优惠卷

export const getUserCouponList = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/userCouponList ",
        data: data
    });
};
//激活优惠卷接口
export const activatedCoupon = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/activatedCoupon",
        data: data
    });
};
//获取优惠券详情

export const getCouponInfo = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/couponInfo",
        data: data
    });
};

// 关注用户
export const addFollow = userId => {
    return request({
        method: "POST",
        url: "/app/v1_0/user/followings",
        data: {
            target: userId
        }
    });
};

// 取消关注用户
export const deleteFollow = userId => {
    return request({
        method: "DELETE",
        url: `/app/v1_0/user/followings/${userId}`
    });
};

// 获取用户资料
export const getUserProfile = () => {
    return request({
        method: "GET",
        url: "/app/v1_0/user/profile"
    });
};

// 获取用户资料
export const updateUserProfile = data => {
    return request({
        method: "PATCH",
        url: "/app/v1_0/user/profile",
        data
    });
};

// 编辑用户图片资料
export const updateUserPhoto = data => {
    return request({
        method: "PATCH",
        url: "/app/v1_0/user/photo",
        data
    });
};

export const haveCoupon = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/haveCoupon",
        data: data
    });
};
export const wtdecrypt = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/wtdecrypt",
        data: data
    });
};
export const getTokenForTicket = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/getTokenForTicket",
        data: data
    });
};

export const getOauth2AccessToken = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/getOauth2AccessToken",
        data: data
    });
};

export const internetList = data => {
    return request({
        method: "POST",
        url: aixosurl + "/interapi/list",
        data: data
    });
};

export const getRightsById = data => {
    return request({
        method: "POST",
        url: aixosurl + "/interapi/getRightsById",
        data: data
    });
};

export const recieveRights = data => {
    return request({
        method: "POST",
        url: aixosurl + "/interapi/recieveRights",
        data: data
    });
};
export const getUserInernetRightsList = data => {
    return request({
        method: "POST",
        url: aixosurl + "/interapi/getUserInernetRightsList",
        data: data
    });
};
export const recieveCnpcRights = data => {
    return request({
        method: "POST",
        url: aixosurl + "/allOtherApi/recieveCnpcRights",
        data: data
    });
};
export const recievePinRights = data => {
    return request({
        method: "POST",
        url: aixosurl + "/allOtherApi/recievePinRights",
        data: data
    });
};
export const getUserCnpcRightsList = data => {
    return request({
        method: "POST",
        url: aixosurl + "/allOtherApi/getUserCnpcRightsList",
        data: data
    });
};

export const getUserPinList = data => {
    return request({
        method: "POST",
        url: aixosurl + "/allOtherApi/getUserPinList",
        data: data
    });
};

export const getWxConfig = data => {
    return request({
        method: "POST",
        url: aixosurl + "/wxPay/getWxConfig",
        data: data
    });
};

export const insertBusiOperationLogNew = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/insertBusiOperationLogNew",
        data: data
    });
};

export const record = data => {
    return request({
        method: "POST",
        url: aixosurl + "/visit/record",
        data: data
    });
};
export const getNotice = data => {
    return request({
        method: "POST",
        url: aixosurl + "/api/getNotice",
        data: data
    });
};

// 获取归属地城市信息
export const getUserArea = data => {
    return request({
        method: "post",
        url: aixosurl + "/api/getUserArea",
        data: data
    });
};