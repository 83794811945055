import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { record } from "@/api/user";
// 加载vant库和使用样式
import Vant from "vant";
import "vant/lib/index.css";

// 加载全局样式（最好放在最后，方便覆盖前面的样式）
import "./styles/index.less";

// 自动设置rem基准值（HTML标签字体大小）
import "amfe-flexible";

// 引进时间格式化工具
import "./utils/dayjs";

// 引入复制插件
import VueClipBoard from "vue-clipboard2";

// import VConsole from 'vconsole';
// let vconsole = new VConsole();
Vue.use(Vant);

Vue.use(VueClipBoard);

Vue.prototype.record = record;

Vue.config.productionTip = false;
console.log(process.env.NODE_ENV);
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

let head = document.getElementsByTagName("head");
let meta = document.createElement("meta");
// meta.content = 'upgrade-insecure-requests';
meta.httpEquiv = "Content-Security-Policy";
head[0].appendChild(meta);
let meta1 = document.createElement("meta");
meta1.name = "viewport";
meta1.content = "width=device-width,initial-scale=1.0,user-scalable=no";
head[0].appendChild(meta1);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
